import {FC} from 'react'
import Machines from './components/Machines'
import Maintenance from './components/Maintenance'
import TaskLog from './components/TaskLog'
import Activity from './components/Activity'

const Home: FC = () => {
  return (
    <div className='row'>
      <div className='col-md-6 col-lg-12 col-xl-6'>
        <Machines />
      </div>
      <div className='col-md-6 col-lg-12 col-xl-6'>
        <Maintenance />
      </div>
      <div className='col-md-6 col-lg-12 col-xl-6'>
        <Activity />
      </div>
      <div className='col-md-6 col-lg-12 col-xl-6'>
        <TaskLog />
      </div>
    </div>
  )
}

export default Home
