import {Task} from '../../../pages/maintenance/components/TaskInterface'
import {TemplateInterface} from '../../../pages/templates/components/TemplateInterface'

const getNextActions = (template: TemplateInterface, task: Task) => {
  if (task.ended_at) return []

  const lastAction = task.activity?.slice(-1)[0]?.type
  const allowedActions = Object.keys(template.actions).filter(
    (action) => template.actions[action].active
  )

  const actionIndex = allowedActions.indexOf(lastAction)
  const nextActions: string[] = allowedActions.slice(actionIndex === -1 ? 1 : actionIndex + 1)
  // Lista de proximas ações disponíveis
  return nextActions
}

const getUserActions = (actions: TemplateInterface['actions']) => {
  return Object.keys(actions)
    .filter((k) => actions[k].active)
    .map((a) => ({
      name: a,
      notification: actions[a].notification
        ? Object.keys(actions[a].notification).filter((n) => actions[a].notification[n])
        : [],
    }))
}

export {getNextActions, getUserActions}
