import {createContext, useContext, useState, useEffect, SetStateAction} from 'react'
import axios from 'axios'
import {socket} from '../socket'
import MachineInterface from '../../pages/machines/components/MachineInterface'

interface MachineContextType {
  machines: MachineInterface[]
  setMachines: (value: SetStateAction<MachineInterface[]>) => void
  addMachine: (insertedMachine: MachineInterface, insertedImage: File | null) => Promise<void>
  updateMachine: (updatedMachine: MachineInterface, updatedImage: File | null) => Promise<void>
  removeMachine: (id: number) => Promise<void>
}

const MachineContext = createContext<MachineContextType | undefined>(undefined)

export const useMachineContext = () => {
  const context = useContext(MachineContext)
  if (!context) {
    throw new Error('useMachineContext must be used within a MachineProvider')
  }
  return context
}

export function MachineContextProvider({children}) {
  const [machines, setMachines] = useState<MachineInterface[]>([])

  useEffect(() => {
    fetchMachines()

    // Handle socket events
    socket.connect()

    socket.on('connect', () => {
      console.debug('machineContext connected to server', socket.id)
    })

    socket.on('disconnect', () => {
      console.debug('machineContext disconnected from server')
    })

    socket.on('machine-removed', (removedMachine) => {
      setMachines((prevMachines) => prevMachines.filter((m) => m.id !== removedMachine.id))
    })

    socket.on('machine-updated', (updatedMachine) => {
      setMachines((prevMachines) => {
        Object.keys(updatedMachine).forEach((key) => {
          if (updatedMachine[key] === undefined) delete updatedMachine[key]
        })

        return prevMachines.map((m) =>
          m.id === updatedMachine.id ? Object.assign(m, updatedMachine) : m
        )
      })
    })

    socket.on('machine-inserted', (insertedMachine) => {
      setMachines((prevMachines) => [...prevMachines, insertedMachine])
    })

    return () => {
      socket.off('machine-removed')
      socket.off('machine-updated')
      socket.off('machine-inserted')
    }
  }, [])

  const fetchMachines = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/machine/list`)
      .then((response) => {
        setMachines(response.data)
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeMachine = async (id: number) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/machine/${id}`)
      .then((response) => {
        console.log(response)

        setMachines((prevMachines) => prevMachines.filter((m) => m.id !== id))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateMachine = async (updatedMachine: MachineInterface, updatedImage: File | null) => {
    const formData = new FormData()
    if (updatedImage) formData.append('file', updatedImage)

    // Append the JSON data as a string to the FormData object
    formData.append('data', JSON.stringify(updatedMachine))

    const headers = {'Content-Type': 'multipart/form-data'}

    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/machine/${updatedMachine.id}`, formData, {
        headers,
      })
      .then((response) => {
        console.log(response)
        if (response.data.pic) updatedMachine.pic = response.data.pic

        Object.keys(updatedMachine).forEach((key) => {
          if (updatedMachine[key] === undefined) delete updatedMachine[key]
        })

        setMachines((prevMachines) =>
          prevMachines.map((m) =>
            m.id === updatedMachine.id ? Object.assign(m, updatedMachine) : m
          )
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addMachine = async (insertedMachine: MachineInterface, insertedImage: File | null) => {
    const formData = new FormData()
    if (insertedImage) formData.append('file', insertedImage)

    // Append the JSON data as a string to the FormData object
    formData.append('data', JSON.stringify(insertedMachine))

    const headers = {'Content-Type': 'multipart/form-data'}

    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/machine`, formData, {headers})
      .then((response) => {
        console.log(response)

        const {id_machine, pic} = response.data
        setMachines((prevMachines) => {
          insertedMachine.id = id_machine
          insertedMachine.pic = pic
          return [...prevMachines, insertedMachine]
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  return (
    <MachineContext.Provider
      value={{machines, setMachines, addMachine, updateMachine, removeMachine}}
    >
      {children}
    </MachineContext.Provider>
  )
}
