import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import UserInterface from '../../users/components/UserInterface'

type Props = {
  users?: UserInterface[]
}

const UsersList: FC<Props> = ({users = undefined}) => {
  return (
    <>
      {users &&
        users.map((user, i) => {
          return (
            <OverlayTrigger
              key={`${i}-${user.name}`}
              placement='top'
              overlay={<Tooltip id='tooltip-user-name'>{user.name}</Tooltip>}
            >
              <div className='symbol symbol-35px symbol-circle'>
                <img
                  src={toAbsoluteUrl(user?.pic ? user.pic : '/media/avatars/blank.png')}
                  alt={user.name}
                />
              </div>
            </OverlayTrigger>
          )
        })}
    </>
  )
}

export {UsersList}
