import {KTIcon} from '../../_metronic/helpers'

interface Props {
  children: string
  searchTerm: string
  setSearchTerm: (term: string) => void
  className?: string
}

const InputSearch = ({children, searchTerm, setSearchTerm, className}: Props) => (
  <div className='card-title'>
    <div className='d-flex align-items-center position-relative'>
      <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-3' />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className={`form-control form-control-solid ps-13 ${className}`}
        placeholder={children}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  </div>
)

export default InputSearch
