import {useState} from 'react'
import {Link, useSearchParams, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {resetPassword} from '../core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'

const initialValues = {
  password: '',
  passwordConfirmation: '',
}

export function ResetPassword() {
  const {t} = useTranslation(['auth'])
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [viewPassword, setViewPassword] = useState(false)
  const [viewConfirmation, setViewConfirmation] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const token = searchParams.get('token')

  const passwordValidationSchema = Yup.string()
    .min(8, t('form.password.min'))
    .max(16, t('form.password.max'))
    .matches(/[a-zA-Z]/, t('form.password.alphabetic'))
    .matches(/[0-9]/, t('form.password.numeric'))

  const resetPasswordSchema = Yup.object().shape({
    password: passwordValidationSchema.required(t('form.password.required')),
    passwordConfirmation: passwordValidationSchema
      .required(t('form.confirmation.required'))
      .oneOf([Yup.ref('password')], t('form.confirmation.match')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      console.log(token)
      if (!token) return

      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(values.password, token as string)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            setTimeout(navigate, 5000, '/auth/login')
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(t('form.failed'))
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>{t('reset_password.title')}</h1>

        <div className='text-gray-500 fw-semibold fs-6'>{t('reset_password.subtitle')}</div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text'>{t('reset_password.error')}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-lg-10 alert alert-success'>
          <div className='alert-text'>{t('reset_password.success')}</div>
        </div>
      )}

      <div className='form-floating fv-row my-8'>
        <div className='fv-row mb-3'>
          <div className='input-group'>
            <div className='form-floating'>
              <input
                id='password'
                placeholder={t('form.password.label')}
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                type={viewPassword ? 'text' : 'password'}
                autoComplete='off'
              />
              <label htmlFor='password' className='fs-6 fw-bold text-gray-500'>
                {t('form.password.label')}
              </label>
            </div>
            <button
              type='button'
              className='input-group-text py-0'
              onClick={() => setViewPassword(!viewPassword)}
              aria-label='show password'
            >
              <KTIcon iconName={viewPassword ? 'eye' : 'eye-slash'} className='fs-2x' />
            </button>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-3'>
          <div className='input-group'>
            <div className='form-floating'>
              <input
                id='confirmation'
                placeholder={t('form.confirmation.label')}
                {...formik.getFieldProps('passwordConfirmation')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                  },
                  {
                    'is-valid':
                      formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                  }
                )}
                type={viewConfirmation ? 'text' : 'password'}
                autoComplete='off'
              />
              <label htmlFor='confirmation' className='fs-6 fw-bold text-gray-500'>
                {t('form.confirmation.label')}
              </label>
            </div>
            <button
              type='button'
              className='input-group-text py-0'
              onClick={() => setViewConfirmation(!viewConfirmation)}
              aria-label='show password confirmation'
            >
              <KTIcon iconName={viewConfirmation ? 'eye' : 'eye-slash'} className='fs-2x' />
            </button>
          </div>
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.passwordConfirmation}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>{t('button.submit')}</span>
          {loading && (
            <span className='indicator-progress'>
              {t('button.wait')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {t('button.cancel')}
          </button>
        </Link>
      </div>
    </form>
  )
}
