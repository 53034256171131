import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import axios from 'axios'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUser} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {socket} from '../../socket'
import {regularActions, settingsActions} from '../../permissions/components/PermissionInterface'
import {Action as MaintenanceAction} from '../../../pages/templates/components/TemplateInterface'

type PermissionAction =
  | (typeof regularActions)[number]
  | (typeof settingsActions)[number]
  | (typeof regularActions)[number][]
  | (typeof settingsActions)[number][]

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  isUserAllowed: (field: string, action?: PermissionAction) => Boolean
  isMaintenanceAllowed: (
    template: number | null,
    action?: MaintenanceAction | MaintenanceAction[]
  ) => Boolean
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isUserAllowed: () => false,
  isMaintenanceAllowed: () => false,
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const isUserAllowed = (field: string, action?: PermissionAction): boolean => {
    if (!currentUser) return false
    const {role, permissions} = currentUser

    return verifyPermissions(role, permissions, field, action)
  }

  const isMaintenanceAllowed = (
    template: number | null,
    action?: MaintenanceAction | MaintenanceAction[]
  ): boolean => {
    if (!currentUser || !currentUser.templates) return false
    const {templates} = currentUser

    // Se template estiver a NULL, verifica ações em todas as templates
    if (!template) {
      // Se ação não estiver definida, permite visualização
      if (!action) return true

      // Verifica ações
      return templates.some((t) =>
        typeof action === 'string'
          ? t.actions.map((a) => a.name).includes(action)
          : action.some((a) => t.actions.map((a) => a.name).includes(a))
      )
    }

    // Encontra ações na template escolhida
    const templateActions = templates.find((t) => t.id === template)?.actions
    if (!templateActions) return false

    // Se ação não estiver definida, permite visualização
    if (!action) return true

    // Verifica ações
    return typeof action === 'string'
      ? templateActions.map((a) => a.name).includes(action)
      : action.some((a) => templateActions.map((a) => a.name).includes(a))
  }

  const verifyPermissions = (
    role: string,
    permissions: any,
    field: string,
    action?: PermissionAction
  ) => {
    // Utilizador de Kiosk não tem acesso
    if (role === 'user_kiosk') return false
    // Administrador tem acesso completo
    if (role === 'admin') return true

    // Prossegue apenas se permissões estiverem definidas
    if (!permissions || !permissions[field]?.length) return false

    // Se ação não estiver definida, verifica visualização
    if (!action) return true

    // Seleciona a lista de ações apropriada com base no campo
    const actionList: ReadonlyArray<any> = field === 'settings' ? settingsActions : regularActions

    // Verifica permissão para ação ou lista de ações
    const checkPermission = (a: PermissionAction) =>
      actionList.includes(a) && permissions[field].includes(a)
    return typeof action === 'string' ? checkPermission(action) : action.some(checkPermission)
  }

  const logout = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/logout`).then((response) => {
      saveAuth(undefined)
      setCurrentUser(undefined)
      socket.removeAllListeners()
      socket.disconnect()
    })
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        isUserAllowed,
        isMaintenanceAllowed,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUser()
          if (data && data.type === 'user') {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) requestUser()
    else setShowSplashScreen(false)
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
