import {getI18n} from 'react-i18next'

export function getBackground(status: 0 | 1, bg?: string): string {
  switch (status) {
    case 0:
      return bg ? `bg-${bg}-success` : 'bg-success'
    case 1:
      return bg ? `bg-${bg}-warning` : 'bg-warning'
    default:
      return bg ? `bg-${bg}-danger` : 'bg-danger'
  }
}

export function formatTime(time: number): string {
  const days = Math.floor(time / (3600 * 24))
  const hours = Math.floor((time % (3600 * 24)) / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.floor(time % 60)

  const formattedDays = days > 0 ? `${days}d ` : ''
  const formattedHours = hours > 0 ? `${hours}h ` : ''
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : ''
  const formattedSeconds = seconds > 0 ? `${seconds}s` : ''

  return formattedDays + formattedHours + formattedMinutes + formattedSeconds
}

export function formatDate(date: Date | number): string {
  const lang = getI18n().language
  const now = new Date()
  const newDate = new Date(date)

  const isSameDay = now.toDateString() === newDate.toDateString()
  const isSameYear = now.getFullYear() === newDate.getFullYear()
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: isSameYear ? undefined : 'numeric',
    month: isSameDay ? undefined : '2-digit',
    day: isSameDay ? undefined : '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  return newDate.toLocaleString(lang, dateOptions)
}

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
}

export function formatLocaleDate(date: Date | number): string {
  const inputDate = new Date(date)

  if (isNaN(inputDate.getTime())) return ''

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  const timeDiff = Math.floor((inputDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24))
  const lang = getI18n().language

  if (Math.abs(timeDiff) < 2) {
    const timeString = inputDate.toLocaleTimeString(lang, timeOptions)
    const dateKey = timeDiff === -1 ? 'yesterday' : timeDiff === 0 ? 'today' : 'tomorrow'
    const ns = {ns: 'calendar'}
    const t = getI18n().t

    return `${t('date.' + dateKey, ns)} ${t('date.at', ns)} ${timeString}`
  }

  const isSameYear = inputDate.getFullYear() === currentDate.getFullYear()
  return inputDate.toLocaleDateString(lang, {
    year: isSameYear ? undefined : 'numeric',
    month: isSameYear ? 'long' : 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function getDatetime(date: Date | number): string {
  const currentDate = new Date(date)
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function getDate(date: Date | number): string {
  const currentDate = new Date(date)
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}
