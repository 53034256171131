import {FC, useState, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatDate} from '../../../helpers'
import {useTaskContext} from '../../../modules/tasks'
import {useUserContext} from '../../../modules/users'
import './TaskLog.css'

interface IActivity {
  name: string
  type: string
  date: Date
  user?: string
  id_task: number
}

const eventType = [
  'create',
  'approve',
  'disapprove',
  'seen',
  'start',
  'end',
  'validate',
  'invalidate',
] as string[]
type EventType = (typeof eventType)[number]

const LastTasks: FC = () => {
  const {t, i18n} = useTranslation(['home'], {keyPrefix: 'log'})
  const {tasks} = useTaskContext()
  const {users} = useUserContext()
  const [timelineClass, setTimelineClass] = useState('')

  const activityList = useMemo(() => {
    const list: IActivity[] = []

    tasks.forEach((task) => {
      list.push({
        name: task.name,
        type: 'create',
        date: new Date(task.created_at),
        user: users.find((u) => u.id === task.created_by)?.name,
        id_task: task.id,
      })

      if (!task.activity) return

      list.push(
        ...task.activity.map((a) => ({
          name: task.name,
          type: a.type,
          date: new Date(a.created_at),
          user: users.find((u) => u.id === a.created_by)?.name,
          id_task: task.id,
        }))
      )
    })

    return list.sort((a: IActivity, b: IActivity) => b.date.getTime() - a.date.getTime())
  }, [tasks, users])

  const todayTotal = useMemo(() => {
    const today = new Date()
    return activityList.filter((a) => a.date.toDateString() === today.toDateString()).length
  }, [activityList])

  const getEventIcon = (inputString: EventType) => {
    switch (inputString) {
      case 'create':
        return 'calendar-add'
      case 'approve':
        return 'verify'
      case 'disapprove':
        return 'information'
      case 'seen':
        return 'eye'
      case 'start':
        return 'calendar-edit'
      case 'end':
        return 'calendar-tick'
      case 'validate':
        return 'like-shapes'
      case 'invalidate':
        return 'cross-circle'
      default:
        return ''
    }
  }

  useEffect(() => {
    const length = formatDate(
      activityList.reduce(
        (biggest, item) => (item.date < biggest ? item.date : biggest),
        new Date()
      )
    ).length

    if (length < 13) setTimelineClass('')
    else setTimelineClass(`timeline-size-${length > 18 ? '160' : length > 15 ? '140' : '120'}`)
  }, [activityList, i18n.language])

  if (!activityList.length) return null

  return (
    <div className='card mb-5'>
      <div className='card-header border-0'>
        <h3 className='card-title'>{t('title')}</h3>
        <div className='card-toolbar gap-3'>
          <Link to='/maintenance/history'>
            <KTIcon iconName='arrow-up-right' className='fs-1' />
          </Link>
        </div>
      </div>

      <div className='card position-relative px-8 pb-8'>
        <div className={`timeline-label ${timelineClass}`}>
          {activityList.slice(0, todayTotal > 30 ? 30 : todayTotal).map((task, index) => (
            <div key={`activity-${index}`} className='timeline-item'>
              <div className='timeline-label text-gray-800 fs-6'>{formatDate(task.date)}</div>
              <div className='timeline-badge'>
                <KTIcon iconName={getEventIcon(task.type)} className='fs-1' />
              </div>
              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>
                  {task.name === 'corrective' ? t(`corrective`) : task.name}
                </span>
                <span className='fw-mormal text-muted ps-3'>
                  {`${t(task.type)} ${t('by')} ${task.user}`}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {activityList.length > todayTotal || activityList.length > 30 ? (
        <div className='card-footer text-center py-4'>
          <Link to='/maintenance/history'>{t('more')}</Link>
        </div>
      ) : null}
    </div>
  )
}

export default LastTasks
