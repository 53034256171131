import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {useMachineContext} from '../../modules/machines'
import {useAuth} from '../../modules/auth'
import MachineCards from './components/MachineCards'
import MachineList from './components/MachineList'
import MachineInterface from './components/MachineInterface'
import MachineModal from './components/MachineModal'
import {AddButton} from './components/MachineButtons'

const Machines: FC = () => {
  const {t} = useTranslation(['machine'])
  const [view, setView] = useState(true)
  const [selectedMachine, setSelectedMachine] = useState<MachineInterface | null>(null)
  const [showModal, setShowModal] = useState(false)
  const {addMachine, updateMachine, removeMachine} = useMachineContext()
  const {isUserAllowed} = useAuth()

  const handleMachineSave = (updatedMachine: MachineInterface, updatedImage: File | null) => {
    if (!isUserAllowed('machines', 'edit')) {
      toast.warn(t('message.auth'))
      return
    }

    // Editar Máquina
    if (updatedMachine.id)
      toast.promise(updateMachine(updatedMachine, updatedImage), {
        pending: t('message.pending'),
        success: t('message.edit.success'),
        error: t('message.edit.error'),
      })
    // Nova Máquina
    else
      toast.promise(addMachine(updatedMachine, updatedImage), {
        pending: t('message.pending'),
        success: t('message.insert.success'),
        error: t('message.insert.error'),
      })
  }

  const handleMachineRemove = (removedMachine: MachineInterface) => {
    if (!isUserAllowed('machines', 'delete')) {
      toast.warn(t('message.auth'))
      return
    }

    toast.promise(removeMachine(removedMachine.id), {
      pending: t('message.pending'),
      success: t('message.delete.success'),
      error: t('message.delete.error'),
    })
  }

  const handleModal = (machine: MachineInterface | null) => {
    if (!isUserAllowed('machines', 'edit')) {
      toast.warn(t('message.auth'))
      return
    }

    setSelectedMachine(machine)
    setShowModal(true)
  }

  return (
    <>
      <div className='position-relative'>
        <h1>{t('text.machine_park')}</h1>

        <div
          className='position-absolute top-0 end-0'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title={t('tooltip.change_view')}
        >
          <button onClick={() => setView(!view)} className='btn btn-icon'>
            <KTIcon iconName={view ? 'abstract-14' : 'abstract-28'} className='fs-3x' />
          </button>
        </div>
      </div>

      {view ? (
        <MachineCards selectMachine={handleModal} />
      ) : (
        <MachineList selectMachine={handleModal} />
      )}

      <div
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-trigger='hover'
        title={t('tooltip.add_machine')}
      >
        <AddButton selectMachine={() => handleModal(null)} />
      </div>

      <MachineModal
        show={showModal}
        setShow={setShowModal}
        machine={selectedMachine}
        onSave={handleMachineSave}
        onRemove={
          selectedMachine && isUserAllowed('machines', 'delete') ? handleMachineRemove : null
        }
      />
    </>
  )
}
export default Machines
