import {FC, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {formatLocaleDate} from '../../../helpers'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {useUserContext} from '../../../modules/users'
import {useTaskContext} from '../../../modules/tasks'
import {useTemplateContext} from '../../../modules/templates'
import {sortTasks} from '../../../modules/tasks/components/Functions'
import {getNextActions} from '../../../modules/templates/components/Functions'
import {StatusBadge, StatusProgressBar} from '../../../modules/tasks/components/Components'
import {Task} from '../../maintenance/components/TaskInterface'
import {UsersList} from './UsersList'

const Activity: FC = () => {
  const {t} = useTranslation(['home'], {keyPrefix: 'activity'})
  const {isUserAllowed, isMaintenanceAllowed} = useAuth()
  const {templates} = useTemplateContext()
  const {tasks} = useTaskContext()
  const {users} = useUserContext()

  const getStage = (task: Task) => {
    const activity = task.activity.slice().reverse(),
      start = activity.find((a) => a.type === 'start')?.created_at ?? null,
      seen = activity.find((a) => a.type === 'seen')?.created_at ?? null,
      approve = activity.find((a) => a.type === 'approve')?.created_at ?? null

    if (start) return `${t('stage.started_at')} ${formatLocaleDate(start)}`
    if (seen) return `${t('stage.seen_at')} ${formatLocaleDate(seen)}`
    if (approve) return `${t('stage.approved_at')} ${formatLocaleDate(approve)}`
    if (task.scheduled_for)
      return `${t('stage.scheduled_for')} ${formatLocaleDate(task.scheduled_for)}`
    return `${t('stage.created_at')} ${formatLocaleDate(task.created_at)}`
  }

  const getTemplateName = (task: Task) => {
    const template = templates.find((t) => t.id === task.id_template)
    if (!template) return ''
    if (template.name === 'corrective' || template.name === 'preventive')
      return t(`type.${template.name}`)
    return template.name
  }

  const pendingTasks = useMemo(
    () =>
      tasks.filter((task: Task) => {
        if (task.ended_at) return false

        const currentTemplate = templates.find((t) => t.id === task.id_template)
        if (!currentTemplate) return false

        return (
          isUserAllowed('maintenance', 'edit') ||
          isMaintenanceAllowed(task.id_template, getNextActions(currentTemplate, task)[0])
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks, templates]
  )

  return (
    <>
      {pendingTasks.length ? (
        pendingTasks.sort(sortTasks).map((task: Task) => (
          <div className='card mb-5' key={task.id}>
            <div className='card-header border-0'>
              <div className='card-title m-0 py-5'>
                <span className='fw-bold fs-5'>{task.name}</span>
              </div>
              <div className='card-toolbar'>
                <StatusBadge task={task} />
                <Link to='/maintenance/list' state={{id: task.id}} className='ps-4'>
                  <KTIcon iconName='arrow-up-right' className='fs-1' />
                </Link>
              </div>
            </div>
            <div className='card-body pt-0'>
              <div className='fs-6 fw-bolder text-dark'>{getTemplateName(task)}</div>
              <p className='text-gray-400 fw-bold fs-7 mt-1 mb-7'>{getStage(task)}</p>

              <StatusProgressBar task={task} />

              <UsersList
                users={users.filter(
                  (user) =>
                    task.activity.find((a) => a.created_by === user.id) ||
                    user.id === task.created_by
                )}
              />
            </div>
          </div>
        ))
      ) : (
        <div className='card mb-5'>
          <div className='card-header border-0'>
            <div className='card-title m-0 py-5'>
              <span className='fw-bold fs-5'>{t('no_pending_tasks')}</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Activity
