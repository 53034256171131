export type Role = 'admin' | 'user' | 'user_web' | 'user_kiosk'
export const regularActions = ['view', 'edit', 'delete'] as const
export const settingsActions = ['edit', 'delete'] as const

export interface Permissions {
  maintenance: typeof regularActions[number][]
  machines: typeof regularActions[number][]
  teams: typeof regularActions[number][]
  users: typeof regularActions[number][]
  templates: typeof regularActions[number][]
  settings: typeof settingsActions[number][]
}
