import {useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import MachineInterface from './MachineInterface'

interface ModalProps {
  show: boolean
  setShow: (state: boolean) => void
  machine: MachineInterface | null
  onSave: (updatedMachine: MachineInterface, updatedImage: File | null) => void
  onRemove: ((removedMachine: MachineInterface) => void) | null
}

const emptyMachine: MachineInterface = {
  id: 0,
  name: '',
  operational: true,
  pic: undefined,
}

const FILE_SIZE = 720 * 720
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg']

const MachineModal = ({show, setShow, machine, onSave, onRemove}: ModalProps) => {
  const {t} = useTranslation(['machine'], {keyPrefix: 'modal'})
  const [previewFile, setPreviewFile] = useState<string | undefined>(undefined)
  const inputRef = useRef<HTMLInputElement>(null)

  const machineSchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    image: Yup.mixed()
      .nullable()
      .test(
        'fileSize',
        t('image.fileSize'),
        (value) => !value || ((value as File) && (value as File).size <= FILE_SIZE)
      )
      .test(
        'fileType',
        t('image.fileType'),
        (value) => !value || ((value as File) && SUPPORTED_FORMATS.includes((value as File).type))
      ),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: Object.assign(machine || emptyMachine, {image: null}),
    validationSchema: machineSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values, values.image)
      resetForm()
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files && event.currentTarget.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewFile(reader.result as string)
        formik.validateField('image')
      }
      reader.readAsDataURL(file)
      formik.setFieldValue('image', file as File)
    }
  }

  const handleRemove = () => {
    if (onRemove && machine) onRemove(machine)
    resetForm()
  }

  const resetForm = () => {
    setPreviewFile(undefined)
    if (inputRef.current) inputRef.current.value = ''
    setShow(false)
    formik.resetForm()
  }

  return (
    <Modal show={show} onHide={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>{t(`title.${machine ? 'edit' : 'add'}`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='row mb-10'>
            <div className='col-12'>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
              >
                <div
                  className='image-input-wrapper w-150px h-150px'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      previewFile
                        ? previewFile
                        : machine?.pic
                          ? machine.pic
                          : '/media/avatars/blank-machine.png'
                    )})`,
                  }}
                ></div>
              </div>
            </div>
            <div className='col-12'>
              <input
                type='file'
                ref={inputRef}
                placeholder='Picture'
                accept={SUPPORTED_FORMATS.join(',')}
                onChange={handleFileChange}
              />
              {formik.errors.image && formik.touched.image && <div>{formik.errors.image}</div>}
            </div>
          </div>
          <div className='mb-10'>
            <label className='required form-label'>{t('name.label')}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              maxLength={255}
              placeholder={t('name.placeholder')}
              {...formik.getFieldProps('name')}
            />
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' disabled={formik.isSubmitting} onClick={resetForm}>
          {t('button.cancel')}
        </Button>
        {onRemove && machine && (
          <Button variant='danger' disabled={formik.isSubmitting} onClick={handleRemove}>
            {t('button.delete')}
          </Button>
        )}
        <Button
          type='submit'
          variant='primary'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MachineModal
