import {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup' // Import Yup for validation
import {useFormik} from 'formik'
import MultiSelect from '../../../GenericComponents/MultiSelect'
import {useUserContext} from '../../../modules/users'
import {TeamInterface} from './TeamInterface'

interface ModalProps {
  show: boolean
  setShow: (state: boolean) => void
  team: TeamInterface | null
  onSave: (updatedTeam: TeamInterface) => void
  onRemove: ((removedTeam: TeamInterface) => void) | null
}

const emptyTeam: TeamInterface = {
  id: null,
  name: '',
  id_users: undefined,
  updated_by: undefined,
  updated_at: undefined,
}

const TeamModal = ({show, setShow, team, onSave, onRemove}: ModalProps) => {
  const {t} = useTranslation(['teams'], {keyPrefix: 'form'})
  const {users} = useUserContext()
  const [selectedOptions, setSelectedOptions] = useState<number[]>(team?.id_users || [])

  // Define the validation schema for the TeamInterface
  const teamSchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    id_users: Yup.array(Yup.number()).min(1, t('users.required')).required(t('users.required')),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: team || emptyTeam,
    validationSchema: teamSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
      resetForm()
    },
  })

  const handleRemove = () => {
    if (onRemove && team) onRemove(team)
    resetForm()
  }

  const resetForm = () => {
    setShow(false)
    setTimeout(() => {
      formik.resetForm({values: emptyTeam})
    }, 150)
  }

  useEffect(() => {
    formik.setTouched({id_users: true}, false)
    formik.setFieldValue('id_users', selectedOptions)
    formik.validateField('id_users')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  useEffect(() => {
    if (show) {
      formik.resetForm({values: team || emptyTeam})
      setSelectedOptions(team?.id_users || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, team])

  return (
    <Modal show={show} onHide={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>{t(`title.${team ? 'edit' : 'add'}`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className='mb-10'>
            <label className='required form-label'>{t('name.label')}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              maxLength={45}
              placeholder={t('name.placeholder')}
              {...formik.getFieldProps('name')}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className='text-danger'>{formik.errors.name}</div>
            ) : null}
          </div>

          <div className='mb-10'>
            <label className='required form-label'>{t('users.label')}</label>
            <MultiSelect
              options={users.map((u) => ({
                value: Number(u.id),
                label: u.name,
              }))}
              selectedOptions={formik.values.id_users || []}
              setSelectedOptions={setSelectedOptions}
              allOption={t('users.all')}
              noneOption={t('users.none')}
            >
              {t('users.placeholder')}
            </MultiSelect>
            {formik.touched.id_users && formik.errors.id_users ? (
              <div className='text-danger'>{formik.errors.id_users}</div>
            ) : null}
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' disabled={formik.isSubmitting} onClick={resetForm}>
          {t('button.cancel')}
        </Button>
        {onRemove && team && (
          <Button variant='danger' disabled={formik.isSubmitting} onClick={handleRemove}>
            {t('button.delete')}
          </Button>
        )}
        <Button
          type='submit'
          variant='primary'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TeamModal
