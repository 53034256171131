import React from 'react'
import {useTranslation} from 'react-i18next'
import {UserModel, useAuth} from '../../auth'

interface PermissionItemProps {
  permission: string
  item: UserModel
}

const PermissionItem = React.memo(({permission, item}: PermissionItemProps) => {
  const {t} = useTranslation(['permissions'])

  if (!item.permissions || !item.permissions[permission] || !item.permissions[permission].length)
    return null

  const permissionLabel = t(`${permission}.label`) + ' > '
  const permissionItems = item.permissions[permission]
    .map((item: string) => t(`${permission}.${item}`))
    .join(', ')

  return (
    <span className='text-muted fw-semibold text-muted d-block fs-7'>
      {permissionLabel + permissionItems}
    </span>
  )
})

const PermissionList = React.memo(({item}: {item: UserModel}) => {
  const {t} = useTranslation(['permissions'])

  // Type check function
  const isUserModel = () => {
    return (item as UserModel).email !== undefined
  }

  const getPermissionList = () => {
    return item.permissions
      ? Object.keys(item.permissions).filter(
          (permission) => item.permissions && item.permissions[permission].length
        )
      : []
  }

  const renderPermissions = () => {
    if (item.role === 'admin')
      return (
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {t('permissions.full')}
        </span>
      )

    const list = getPermissionList()
    if (!item.permissions || !list.length)
      return (
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {t('permissions.none')}
        </span>
      )

    return list.map((permission) => (
      <PermissionItem key={permission} permission={permission} item={item} />
    ))
  }

  return (
    <div key={item.name} className='bg-light px-2 py-1 mb-1 rounded'>
      {!isUserModel() ? <span className='text-gray-800 fw-bold fs-6'>{item.name}</span> : null}
      <span className={`text-gray-800 ${isUserModel() ? '' : 'ms-2'}`}>
        {t(`role.${item.role.split('_').slice(-1)[0]}`)}
      </span>
      {renderPermissions()}
    </div>
  )
})

const PermissionBadge = () => {
  const {currentUser} = useAuth()

  return currentUser ? <PermissionList item={currentUser} /> : null
}

export default PermissionBadge
