import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import axios from 'axios'
import {UserModel, useAuth} from '../../modules/auth'
import {PasswordInterface, PinInterface} from './components/SignInInterface'
import ProfileDetails from './components/ProfileDetails'
import ProfilePicture from './components/ProfilePicture'
import ProfilePermissions from './components/ProfilePermissions'
import SignInMethod from './components/SignInMethod'

const Account: FC = () => {
  const {t} = useTranslation(['account'], {keyPrefix: 'message'})
  const {currentUser, setCurrentUser} = useAuth()

  const handlePictureSave = (updatedPicture: File) => {
    const formData = new FormData()
    formData.append('file', updatedPicture)

    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/picture`, formData)
        .then((response) => {
          console.debug(response.data)

          if (response.data.pic)
            setCurrentUser((prevUser) =>
              prevUser && response.data.pic
                ? Object.assign(prevUser, {pic: response.data.pic})
                : prevUser
            )
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('picture.success'),
        error: t('picture.error'),
      }
    )
  }

  const handleProfileSave = (updatedProfile: UserModel) => {
    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/basic`, updatedProfile)
        .then((response) => {
          console.log(response)

          setCurrentUser((prevUser) =>
            prevUser ? Object.assign(prevUser, updatedProfile) : prevUser
          )
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('details.success'),
        error: t('details.error'),
      }
    )
  }

  const handlePasswordUpdate = (password: PasswordInterface) => {
    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/password`, password)
        .then(console.log)
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('password.success'),
        error: t('password.error'),
      }
    )
  }

  const handlePinUpdate = (pin: PinInterface) => {
    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/pin`, pin)
        .then(console.log)
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('pin.success'),
        error: t('pin.error'),
      }
    )
  }

  return (
    <>
      <ProfilePicture onSave={handlePictureSave} />
      {currentUser && <ProfileDetails user={currentUser} onSave={handleProfileSave} />}
      {currentUser && <ProfilePermissions />}
      <SignInMethod onPasswordUpdate={handlePasswordUpdate} onPinUpdate={handlePinUpdate} />
    </>
  )
}

export default Account
