import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'
import {UserContextProvider} from '../modules/users'
import {TemplateContextProvider} from '../modules/templates'
import {MachineContextProvider} from '../modules/machines'
import {TaskContextProvider} from '../modules/tasks'
import {FaultContextProvider} from '../modules/faults'
import Home from '../pages/home'
import Machines from '../pages/machines'
import Teams from '../pages/teams'
import Users from '../pages/users'
import Account from '../pages/account'
import Settings from '../pages/settings'

const PrivateRoutes = () => {
  const Maintenance = lazy(() => import('../pages/maintenance'))
  const Templates = lazy(() => import('../pages/templates'))
  const {isUserAllowed} = useAuth()

  //TODO: ao redirecionar para Home, mostra mensagem "Sem permissão"

  return (
    <UserContextProvider>
      <TemplateContextProvider>
        <MachineContextProvider>
          <TaskContextProvider>
            <FaultContextProvider>
              <Routes>
                <Route element={<MasterLayout />}>
                  {/* Redirect to Home after success login */}
                  <Route path='auth/*' element={<Navigate to='/home' />} />
                </Route>

                <Route element={<MasterLayout />}>
                  {/* Pages */}
                  <Route path='home' element={<Home />} />

                  <Route
                    path='machines'
                    element={isUserAllowed('machines') ? <Machines /> : <Navigate to='/home' />}
                  />

                  <Route
                    path='maintenance/*'
                    element={
                      isUserAllowed('maintenance') ? (
                        <SuspensedView>
                          <Maintenance />
                        </SuspensedView>
                      ) : (
                        <Navigate to='/home' />
                      )
                    }
                  />

                  <Route
                    path='settings'
                    element={isUserAllowed('settings') ? <Settings /> : <Navigate to='/home' />}
                  />
                </Route>

                <Route element={<MasterLayout />}>
                  <Route
                    path='teams'
                    element={isUserAllowed('teams') ? <Teams /> : <Navigate to='/home' />}
                  />

                  <Route
                    path='users'
                    element={isUserAllowed('users') ? <Users /> : <Navigate to='/home' />}
                  />

                  <Route
                    path='template/*'
                    element={
                      isUserAllowed('templates') ? (
                        <SuspensedView>
                          <Templates />
                        </SuspensedView>
                      ) : (
                        <Navigate to='/home' />
                      )
                    }
                  />

                  <Route path='account' element={<Account />} />

                  {/* Page Not Found */}
                  <Route path='*' element={<Navigate to='/error/404' />} />
                </Route>
              </Routes>
            </FaultContextProvider>
          </TaskContextProvider>
        </MachineContextProvider>
      </TemplateContextProvider>
    </UserContextProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
