import {useTranslation} from 'react-i18next'
import {useFormikContext} from 'formik'
import PermissionCheckbox from './components/PermissionCheckbox'
import {regularActions, settingsActions} from './components/PermissionInterface'
import UserInterface from '../../pages/users/components/UserInterface'
import PermissionButton from './components/PermissionButton'

interface SelectProps {
  roles: {
    value: string | number
    label: string
  }[]
}

const Permissions = ({roles}: SelectProps) => {
  const {t} = useTranslation(['permissions'])
  const formik = useFormikContext<UserInterface>()

  const isChecked = (field: string, value: string) =>
    formik.values.permissions?.[field]?.includes(value) || false

  const handleCheckbox = (field: string, value: string) => {
    let updatedValues = formik.values.permissions?.[field] || []

    // Desativa opção selecionada
    if (updatedValues.includes(value)) {
      updatedValues = updatedValues.filter((item: string) => item !== value)
    }
    // Ativa opção selecionada e opções inferiores
    else {
      updatedValues = [
        ...updatedValues,
        value,
        ...(value === 'delete' ? ['view', 'edit'] : value === 'edit' ? ['view'] : []),
      ].filter((item, index, self) => self.indexOf(item) === index)
    }

    formik.setFieldValue(`permissions.${field}`, updatedValues)
  }

  // Helper function to render PermissionCheckbox
  const renderPermissionCheckbox = (label: string) => {
    const [field, permission] = label.split('.')
    return (
      <PermissionCheckbox
        key={label}
        label={label}
        onChange={() => handleCheckbox(field, permission)}
        checked={isChecked(field, permission)}
      />
    )
  }

  const selectAll = (field: string, values: readonly string[]) => {
    const currentValues = formik.values.permissions?.[field] || []
    const updatedValues = currentValues.length ? [] : values

    formik.setFieldValue(`permissions.${field}`, updatedValues)
  }

  // Helper function to render PermissionButton
  const renderPermissionButton = (field: string) => {
    const values = field === 'settings' ? settingsActions : regularActions
    const isSelected = (formik.values.permissions?.[field] || []).length > 0

    return <PermissionButton isSelected={isSelected} onClick={() => selectAll(field, values)} />
  }

  return (
    <>
      {formik.values.role !== 'admin' && roles.map((r) => r.value).includes(formik.values.role) ? (
        <>
          <div className='row'>
            <label className='required form-label'>{t('permissions.label')}</label>
          </div>

          <div className='row row-cols-auto gy-2 mb-5'>
            <div className='col-12'>
              <span className='form-check-label text-gray-700 fs-7 fw-bold ms-0 me-2'>
                {t('maintenance.label')}
              </span>
              {renderPermissionButton('maintenance')}
            </div>

            {regularActions.map((permission) =>
              renderPermissionCheckbox(`maintenance.${permission}`)
            )}
          </div>

          {formik.values.role !== 'user_kiosk' ? (
            <>
              <div className='row row-cols-auto gy-2 mb-5'>
                <div className='col-12'>
                  <span className='form-check-label text-gray-700 fs-7 fw-bold ms-0 me-2'>
                    {t('machines.label')}
                  </span>
                  {renderPermissionButton('machines')}
                </div>

                {regularActions.map((permission) =>
                  renderPermissionCheckbox(`machines.${permission}`)
                )}
              </div>

              <div className='row row-cols-auto gy-2 mb-5'>
                <div className='col-12'>
                  <span className='form-check-label text-gray-700 fs-7 fw-bold ms-0 me-2'>
                    {t('teams.label')}
                  </span>
                  {renderPermissionButton('teams')}
                </div>

                {regularActions.map((permission) =>
                  renderPermissionCheckbox(`teams.${permission}`)
                )}
              </div>

              <div className='row row-cols-auto gy-2 mb-5'>
                <div className='col-12'>
                  <span className='form-check-label text-gray-700 fs-7 fw-bold ms-0 me-2'>
                    {t('users.label')}
                  </span>
                  {renderPermissionButton('users')}
                </div>

                {regularActions.map((permission) =>
                  renderPermissionCheckbox(`users.${permission}`)
                )}
              </div>

              <div className='row row-cols-auto gy-2 mb-5'>
                <div className='col-12'>
                  <span className='form-check-label text-gray-700 fs-7 fw-bold ms-0 me-2'>
                    {t('templates.label')}
                  </span>
                  {renderPermissionButton('templates')}
                </div>

                {regularActions.map((permission) =>
                  renderPermissionCheckbox(`templates.${permission}`)
                )}
              </div>

              <div className='row row-cols-auto gy-2'>
                <div className='col-12'>
                  <span className='form-check-label text-gray-700 fs-7 fw-bold ms-0 me-2'>
                    {t('settings.label')}
                  </span>
                  {renderPermissionButton('settings')}
                </div>

                {settingsActions.map((permission) =>
                  renderPermissionCheckbox(`settings.${permission}`)
                )}
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default Permissions
