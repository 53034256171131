import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PasswordInterface, PinInterface, updatePassword, updatePin} from './SignInInterface'

interface Props {
  onPasswordUpdate: (password: PasswordInterface) => void
  onPinUpdate: (pin: PinInterface) => void
}

const SignInMethod = ({onPasswordUpdate, onPinUpdate}: Props) => {
  const {t} = useTranslation(['account'], {keyPrefix: 'signin'})
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [showPinForm, setPinForm] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const passwordValidationSchema = Yup.string()
    .min(8, t('password.min'))
    .max(16, t('password.max'))
    .matches(/[a-zA-Z]/, t('password.alphabetic'))
    .matches(/[0-9]/, t('password.numeric'))

  const passwordSchema = Yup.object().shape({
    currentPassword: passwordValidationSchema.required(t('password.current.required')),
    newPassword: passwordValidationSchema.required(t('password.new.required')),
    passwordConfirmation: passwordValidationSchema
      .required(t('password.confirm.required'))
      .oneOf([Yup.ref('newPassword')], t('password.confirm.match')),
  })

  const pinSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, t('kiosk.current.min'))
      .max(16, t('kiosk.current.max'))
      .required(t('kiosk.current.required')),
    newPin: Yup.string()
      .matches(/^[0-9]+$/, t('kiosk.pin.match'))
      .min(4, t('kiosk.pin.limit'))
      .max(4, t('kiosk.pin.limit'))
      .required(t('kiosk.pin.required')),
  })

  const formik = useFormik<PasswordInterface>({
    initialValues: updatePassword,
    validationSchema: passwordSchema,
    onSubmit: (values) => {
      setLoading(true)
      onPasswordUpdate(values)
      setLoading(false)
      setPasswordForm(false)
      formik.resetForm()
    },
  })

  const formik2 = useFormik<PinInterface>({
    initialValues: updatePin,
    validationSchema: pinSchema,
    onSubmit: (values) => {
      setLoading(true)
      onPinUpdate(values)
      setLoading(false)
      setPinForm(false)
      formik2.resetForm()
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{t('title')}</h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center mb-10'>
            <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>{t('password.title')}</div>
              <div className='fw-bold text-gray-600'>************</div>
            </div>

            <div
              id='kt_signin_password_edit'
              className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
            >
              <form
                onSubmit={formik.handleSubmit}
                id='kt_signin_change_password'
                className='form'
                noValidate
              >
                <div className='row mb-1'>
                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                        {t('password.current.label')}
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='currentpassword'
                        autoComplete="disabled"
                        {...formik.getFieldProps('currentPassword')}
                      />
                      {formik.touched.currentPassword && formik.errors.currentPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                        {t('password.new.label')}
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='newpassword'
                        autoComplete="disabled"
                        {...formik.getFieldProps('newPassword')}
                      />
                      {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.newPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                        {t('password.confirm.label')}
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='confirmpassword'
                        autoComplete="disabled"
                        {...formik.getFieldProps('passwordConfirmation')}
                      />
                      {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='form-text mb-5'>{t('password.help')}</div>

                <div className='d-flex'>
                  <button
                    id='kt_password_submit'
                    type='submit'
                    className='btn btn-primary me-2 px-6'
                  >
                    {loading ? (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    ) : (
                      t('password.update')
                    )}
                  </button>
                  <button
                    onClick={() => setPasswordForm(false)}
                    id='kt_password_cancel'
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    {t('password.cancel')}
                  </button>
                </div>
              </form>
            </div>

            <div
              id='kt_signin_password_button'
              className={'ms-auto ' + (showPasswordForm && 'd-none')}
            >
              <button
                onClick={() => setPasswordForm(true)}
                className='btn btn-light btn-active-light-primary'
              >
                {t('password.change_button')}
              </button>
            </div>
          </div>

          <div className='d-flex flex-wrap align-items-center mb-10'>
            <div id='kt_signin_pin' className={' ' + (showPinForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>{t('kiosk.title')}</div>
              <div className='fw-bold text-gray-600'>************</div>
            </div>

            <div id='kt_signin_pin_edit' className={'flex-row-fluid ' + (!showPinForm && 'd-none')}>
              <form
                onSubmit={formik2.handleSubmit}
                id='kt_signin_change_pin'
                className='form'
                noValidate
              >
                <div className='row mb-1'>
                  <div className='col-lg-6'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='currentpassword2' className='form-label fs-6 fw-bolder mb-3'>
                        {t('kiosk.current.label')}
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='currentpassword2'
                        autoComplete="disabled"
                        {...formik2.getFieldProps('currentPassword')}
                      />
                      {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.currentPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='newPin' className='form-label fs-6 fw-bolder mb-3'>
                        {t('kiosk.pin.label')}
                      </label>
                      <input
                        type='password'
                        inputMode='numeric'
                        className='form-control form-control-lg form-control-solid '
                        id='newPin'
                        autoComplete="disabled"
                        {...formik2.getFieldProps('newPin')}
                      />
                      {formik2.touched.newPin && formik2.errors.newPin && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.newPin}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='form-text mb-5'>{t('kiosk.help')}</div>

                <div className='d-flex'>
                  <button id='kt_pin_submit' type='submit' className='btn btn-primary me-2 px-6'>
                    {loading ? (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    ) : (
                      t('kiosk.update')
                    )}
                  </button>
                  <button
                    onClick={() => setPinForm(false)}
                    id='kt_pin_cancel'
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    {t('kiosk.cancel')}
                  </button>
                </div>
              </form>
            </div>

            <div id='kt_signin_pin_button' className={'ms-auto ' + (showPinForm && 'd-none')}>
              <button
                onClick={() => setPinForm(true)}
                className='btn btn-light btn-active-light-primary'
              >
                {t('kiosk.change_button')}
              </button>
            </div>
          </div>

          {/*<div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
            <KTIcon iconName='shield-tick' className='fs-2tx text-primary me-4' />
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Proteja a sua Conta</h4>
                <div className='fs-6 text-gray-600 pe-7'>
                  A autenticação de dois fatores adiciona uma camada extra de segurança à sua conta.
                  Para fazer login, além disso, você precisará fornecer um código de 6 dígitos.
                </div>
              </div>
              <button
                className='btn btn-primary px-6 align-self-center text-nowrap'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_two_factor_authentication'
              >
                Ativar
              </button>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  )
}

export default SignInMethod
