import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {KTIcon} from '../../../../_metronic/helpers'
import {useMachineContext} from '../../../modules/machines'

const Machines: FC = () => {
  const {t} = useTranslation(['home'], {keyPrefix: 'machines'})
  const {machines} = useMachineContext()

  return (
    <div className='card mb-5'>
      <div className='card-header border-0'>
        <h3 className='card-title'>{t('title')}</h3>
        <div className='card-toolbar gap-3'>
          <Link to='/machines'>
            <KTIcon iconName='arrow-up-right' className='fs-1' />
          </Link>
        </div>
      </div>

      <div className='px-8 pb-8 collapse show'>
        <div className='card position-relative'>
          <div className='row row-cols-1 row-cols-sm-3 gy-5'>
            <div className='col'>
              <div className='bg-light-danger rounded-2 px-6 py-8'>
                <span>{t('inactive')}</span>
                <h3>{machines.filter((d) => !d.operational).length}</h3>
              </div>
            </div>
            <div className='col'>
              <div className='bg-light-success rounded-2 px-6 py-8'>
                <span>{t('active')}</span>
                <h3>{machines.filter((d) => d.operational).length}</h3>
              </div>
            </div>
            <div className='col'>
              <div className='bg-light-primary rounded-2 px-6 py-8'>
                <span>{t('total')}</span>
                <h3>{machines.length}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Machines
