import {FC, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import axios from 'axios'
import {useAuth} from '../../modules/auth'
import {useFaultContext} from '../../modules/faults'
import {CompanyInterface, FaultInterface, KioskInterface} from './components/SettingsInterface'
import CompanyDetails from './components/CompanyDetails'
import FaultList from './components/FaultList'
import KioskAccess from './components/KioskAccess'

const Settings: FC = () => {
  const {t} = useTranslation(['settings'])
  const [company, setCompany] = useState<CompanyInterface | null>(null)
  const [kioskList, setKioskList] = useState<KioskInterface[] | null>(null)
  const {faults, addFault, updateFault, removeFault} = useFaultContext()
  const {currentUser, isUserAllowed} = useAuth()

  const toastMessage = {
    pending: t('message.pending'),
    success: t('message.general.success'),
    error: t('message.general.error'),
  }

  const fetchCompany = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/company`).then((response) => {
      setCompany(response.data)
    })
  }

  const fetchKiosk = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/kiosk/list`).then((response) => {
      setKioskList(response.data)
    })
  }

  const handleCompanySave = (updatedCompany: CompanyInterface) => {
    if (!isUserAllowed('settings', 'edit')) {
      toast.warn(t('message.auth'))
      return
    }

    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/company/`, updatedCompany)
        .then((response) => {
          console.log(response)

          setCompany(updatedCompany)
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      toastMessage
    )
  }

  const handleFaultSave = (updatedFault: FaultInterface) => {
    if (!isUserAllowed('settings', 'edit')) {
      toast.warn(t('message.auth'))
      return
    }

    // Editar ou inserer Motivo
    toast.promise(
      updatedFault.id ? updateFault(updatedFault) : addFault(updatedFault),
      toastMessage
    )
  }

  const handleFaultRemove = (removedFault: FaultInterface) => {
    if (!isUserAllowed('settings', 'delete')) {
      toast.warn(t('message.auth'))
      return
    }

    if (!removedFault.id) return

    toast.promise(removeFault(removedFault.id), toastMessage)
  }

  const handleKioskSave = (updatedKiosk: KioskInterface) => {
    if (!isUserAllowed('settings', 'edit')) {
      toast.warn(t('message.auth'))
      return
    }

    // Editar Kiosk
    if (updatedKiosk.id) {
      toast.promise(
        axios
          .post(`${process.env.REACT_APP_API_URL}/api/kiosk/${updatedKiosk.id}`, updatedKiosk)
          .then((response) => {
            console.log(response)

            setKioskList((prevKioskList) =>
              prevKioskList
                ? prevKioskList.map((m) => {
                    if (m.id === updatedKiosk.id) {
                      updatedKiosk.updated_at = new Date()
                      updatedKiosk.updated_by = currentUser ? currentUser.name : ''
                      return updatedKiosk
                    } else return m
                  })
                : null
            )
          })
          .catch((error) => {
            console.error(error.response.data.error)
            return Promise.reject()
          }),
        toastMessage
      )
    }
    // Novo Kiosk
    else {
      toast.promise(
        axios
          .put(`${process.env.REACT_APP_API_URL}/api/kiosk`, updatedKiosk)
          .then((response) => {
            console.log(response)

            const {id_kiosk} = response.data
            setKioskList((prevKioskList) => {
              updatedKiosk.id = id_kiosk
              updatedKiosk.updated_at = new Date()
              updatedKiosk.updated_by = currentUser ? currentUser.name : ''
              return prevKioskList ? [...prevKioskList, updatedKiosk] : [updatedKiosk]
            })
          })
          .catch((error) => {
            console.error(error.response.data.error)
            return Promise.reject()
          }),
        toastMessage
      )
    }
  }

  const handleKioskRemove = (removedKiosk: KioskInterface) => {
    if (!isUserAllowed('settings', 'delete')) {
      toast.warn(t('message.auth'))
      return
    }

    toast.promise(
      axios
        .delete(`${process.env.REACT_APP_API_URL}/api/kiosk/${removedKiosk.id}`)
        .then(() => {
          setKioskList((prevKioskList) =>
            prevKioskList ? prevKioskList.filter((m) => m.id !== removedKiosk.id) : null
          )
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      toastMessage
    )
  }

  useEffect(() => {
    fetchKiosk()
    fetchCompany()
  }, [])

  return (
    <>
      {company && <CompanyDetails company={company} onSave={handleCompanySave} />}
      <FaultList list={faults} onSave={handleFaultSave} onRemove={handleFaultRemove} />
      <KioskAccess list={kioskList} onSave={handleKioskSave} onRemove={handleKioskRemove} />
    </>
  )
}

export default Settings
