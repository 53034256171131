import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {CompanyInterface} from './SettingsInterface'

interface CompanyProps {
  company: CompanyInterface
  onSave: (updatedCompany: CompanyInterface) => void
}

const CompanyDetails = ({company, onSave}: CompanyProps) => {
  const {t} = useTranslation(['settings'], {keyPrefix: 'general'})

  // Define the validation schema for the CompanyInterface
  const companySchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: company,
    validationSchema: companySchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_settings_company_details'
        aria-expanded='true'
        aria-controls='kt_settings_company_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{t('title')}</h3>
        </div>
      </div>

      <div id='kt_settings_company_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                {t('name.label')}
              </label>

              <div className='col-lg-8  fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder={t('name.placeholder')}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CompanyDetails
