import {FC} from 'react'
import {Dropdown} from 'react-bootstrap/esm'

interface Option {
  value: string | number
  label: string
}

interface MultiSelectProps {
  children: string
  options: Option[]
  selectedOptions: Array<number | string>
  setSelectedOptions: React.Dispatch<React.SetStateAction<any[]>>
  allOption?: string
  noneOption?: string
}

const MultiSelect: FC<MultiSelectProps> = ({
  children,
  options,
  selectedOptions,
  setSelectedOptions,
  allOption,
  noneOption,
}) => {
  const isOptionSelected = (key: string | number): boolean => selectedOptions.includes(key)

  const handleToggleAll = () => setSelectedOptions(options.map((o) => o.value))

  const handleOptionToggle = (value: string | number) => {
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(value))
        return Object.values(prevOptions).filter((option) => option !== value)
      else return [...prevOptions, value]
    })
  }

  const getLabel = () => {
    if (selectedOptions.length) {
      const selected = options.filter((o) => selectedOptions.includes(o.value))
      if (allOption && options.length === selected.length) return allOption
      else return selected.map((o) => o.label).join(', ')
    }
    return children
  }

  return (
    <Dropdown drop='down' align='start' autoClose='outside'>
      <Dropdown.Toggle id='dropdownSelect' className='btn btn-light-primary text-truncate mw-100'>
        {getLabel()}
      </Dropdown.Toggle>
      <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-250px p-2'>
        {allOption && options.length !== selectedOptions.length ? (
          <label key={null} className='dropdown-item menu-link'>
            <div
              className='form-check form-check-inline form-check-solid w-100'
              onClick={() => handleToggleAll()}
            >
              <span className='ps-2 fs-6'>{allOption}</span>
            </div>
          </label>
        ) : null}

        {noneOption && options.length === selectedOptions.length ? (
          <label key={null} className='dropdown-item menu-link'>
            <div
              className='form-check form-check-inline form-check-solid w-100'
              onClick={() => setSelectedOptions([])}
            >
              <span className='ps-2 fs-6'>{noneOption}</span>
            </div>
          </label>
        ) : null}

        {options.map((option) => (
          <label key={option.value} className='dropdown-item menu-link'>
            <div className='form-check form-check-inline form-check-solid w-100'>
              <input
                className='form-check-input'
                type='checkbox'
                name='options'
                value={option.value}
                checked={isOptionSelected(option.value)}
                onChange={() => handleOptionToggle(option.value)}
              />
              <span className='ps-2 fs-6'>{option.label}</span>
            </div>
          </label>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MultiSelect
