interface Props {
  name?: string
  children: string
  className?: string
  value: boolean
  onBlur?: (e: any) => void
  onChange: (value: boolean) => void
}

const SwitchButton = ({name, children, className, value, onBlur, onChange}: Props) => {
  const id = `${name ?? 'switchButton'}`

  const handleChange = (event: React.ChangeEvent<any>) => {
    onChange(event.target.checked)
  }

  const handleBlur = (event: any) => {
    if (onBlur) onBlur(event)
  }

  return (
    <div className={`form-check form-switch form-check-custom form-check-solid ${className}`}>
      <label className='form-label form-check-label text-dark ms-0 me-2 pt-2' htmlFor={id}>
        {children}
      </label>
      <input
        className='form-check-input'
        type='checkbox'
        name={name}
        aria-label={children}
        checked={value}
        onBlur={handleBlur}
        onChange={handleChange}
        id={id}
      />
    </div>
  )
}

export default SwitchButton
