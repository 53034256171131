import {useState, useEffect, useLayoutEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {FormikProvider, useFormik} from 'formik'
import UserInterface from './UserInterface'
import SwitchButton from '../../../GenericComponents/SwitchButton'
import SelectButton from '../../../GenericComponents/SelectButton'
import Permissions from '../../../modules/permissions'

interface ModalProps {
  show: boolean
  setShow: (state: boolean) => void
  user: UserInterface | null
  onSave: (updatedUser: UserInterface) => void
  onRemove: ((removedUser: UserInterface) => void) | null
}

interface SelectProps {
  value: string | number
  label: string
}

const emptyUser: UserInterface = {
  id: null,
  name: '',
  email: '',
  phone: undefined,
  password: undefined,
  pin: undefined,
  updated_by: undefined,
  updated_at: undefined,
  role: 'user_web',
  permissions: undefined,
}

const UserModal = ({show, setShow, user, onSave, onRemove}: ModalProps) => {
  const {t} = useTranslation(['users'], {keyPrefix: 'modal'})
  const {t: p} = useTranslation(['permissions'])
  const [manualPassword, setManualPassword] = useState(false)
  const [manualPin, setManualPin] = useState(false)
  const [selectedRole, setSelectedRole] = useState<SelectProps | null>(null)

  const roles = [
    {value: 'admin', label: p('role.admin')},
    {value: 'user', label: p('role.user')},
    {value: 'user_web', label: p('role.web')},
    {value: 'user_kiosk', label: p('role.kiosk')},
  ]

  const permissionSchema = (keys, schema) =>
    ['user', 'user_web', 'user_kiosk'].includes(keys[0]) ? schema : schema.strip()
  const permissionWebSchema = (keys, schema) =>
    ['user', 'user_web'].includes(keys[0]) ? schema : schema.strip()

  // Define the validation schema for the UserInterface
  const phoneReg = /^9[0-9]{8}|(00[0-9]{8,18})$/
  const userSchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    email: Yup.string().email(t('email.invalid')).required(t('email.required')),
    phone: Yup.string().matches(phoneReg, t('phone.invalid')).default(null).nullable(),
    password: manualPassword
      ? Yup.string()
          .min(8, t('password.min'))
          .max(16, t('password.max'))
          .matches(/[a-zA-Z]/, t('password.alphabetic'))
          .matches(/[0-9]/, t('password.numeric'))
          .required(t('password.required'))
      : Yup.string().nullable(),
    pin: manualPin
      ? Yup.string()
          .min(4, t('pin.limit'))
          .max(4, t('pin.limit'))
          .matches(/[0-9]/, t('pin.numeric'))
          .required(t('pin.required'))
      : Yup.string().nullable(),
    role: Yup.string().required(p('role.required')),
    permissions: Yup.object()
      .when('role', (keys, schema) =>
        keys[0] !== 'admin' ? schema.default(null).nullable() : schema.strip()
      )
      .shape({
        maintenance: Yup.array(Yup.string()).when('role', permissionSchema),
        machines: Yup.array(Yup.string()).when('role', permissionWebSchema),
        teams: Yup.array(Yup.string()).when('role', permissionWebSchema),
        users: Yup.array(Yup.string()).when('role', permissionWebSchema),
        templates: Yup.array(Yup.string()).when('role', permissionWebSchema),
        settings: Yup.array(Yup.string()).when('role', permissionWebSchema),
      }),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: user || emptyUser,
    validationSchema: userSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
      resetForm()
    },
  })

  const handleRemove = () => {
    if (onRemove && user) onRemove(user)
    resetForm()
  }

  const resetForm = () => {
    setShow(false)
    setTimeout(() => {
      setManualPassword(false)
      setManualPin(false)
      formik.resetForm({values: emptyUser})
    }, 150)
  }

  useEffect(() => {
    if (selectedRole) formik.setFieldValue('role', selectedRole.value)
    formik.validateField('role')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole])

  useEffect(() => {
    formik.validateField('password')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualPassword, formik.values.password])

  useEffect(() => {
    formik.validateField('pin')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualPin, formik.values.pin])

  useLayoutEffect(() => {
    if (show) {
      formik.resetForm({values: user || emptyUser})

      const role = roles.find((r) => (user ? r.value === user.role : r.value === emptyUser.role))
      if (role) setSelectedRole(role)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, user])

  return (
    <Modal show={show} onHide={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>{t(`title.${user ? 'edit' : 'add'}`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='mb-10'>
            <label className='required form-label'>{t('name.label')}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              maxLength={255}
              placeholder={t('name.placeholder')}
              {...formik.getFieldProps('name')}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className='text-danger'>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className='mb-10'>
            <label className='required form-label'>{t('email.label')}</label>
            <input
              type='email'
              className='form-control form-control-solid'
              maxLength={255}
              placeholder={t('email.placeholder')}
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='text-danger'>{formik.errors.email}</div>
            ) : null}
          </div>
          <div className='mb-10'>
            <label className='form-label'>{t('phone.label')}</label>
            <input
              type='tel'
              inputMode='numeric'
              className='form-control form-control-solid'
              placeholder={t('phone.placeholder')}
              {...formik.getFieldProps('phone')}
            />
          </div>

          <SwitchButton className='mb-5' value={manualPassword} onChange={setManualPassword}>
            {t('password.label')}
          </SwitchButton>

          {manualPassword ? (
            <div className='mb-10'>
              <input
                type='password'
                className='form-control form-control-solid'
                placeholder={t('password.placeholder')}
                autoComplete='disabled'
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className='text-danger'>{formik.errors.password}</div>
              ) : null}
            </div>
          ) : null}

          <SwitchButton className='mb-5' value={manualPin} onChange={setManualPin}>
            {t('pin.label')}
          </SwitchButton>

          {manualPin ? (
            <div className='mb-10'>
              <input
                type='password'
                inputMode='numeric'
                className='form-control form-control-solid'
                placeholder={t('pin.placeholder')}
                autoComplete='disabled'
                {...formik.getFieldProps('pin')}
              />
              {formik.touched.pin && formik.errors.pin ? (
                <div className='text-danger'>{formik.errors.pin}</div>
              ) : null}
            </div>
          ) : null}

          <div className='separator my-5'></div>

          {user?.teams?.length ? (
            <div className='mb-10'>
              <label className='form-label'>
                <span>{p('teams.label')}</span>
              </label>
              <p>{user.teams.map((t) => t.name).join(', ')}</p>
            </div>
          ) : null}

          <div className='mb-10'>
            <label className='required form-label'>
              <span>{p('role.label')}</span>
              <span className='form-text ps-2'>{p('role.description')}</span>
            </label>

            <SelectButton options={roles} currentOption={selectedRole} onSelect={setSelectedRole} />
            {formik.touched.role && formik.errors.role ? (
              <div className='text-danger'>{formik.errors.role}</div>
            ) : null}
          </div>

          <FormikProvider value={formik}>
            <Permissions roles={roles} />
          </FormikProvider>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' disabled={formik.isSubmitting} onClick={resetForm}>
          {t('button.cancel')}
        </Button>
        {onRemove && user && (
          <Button variant='danger' disabled={formik.isSubmitting} onClick={handleRemove}>
            {t('button.delete')}
          </Button>
        )}
        <Button
          type='submit'
          variant='primary'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserModal
