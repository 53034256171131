import {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {FaultInterface} from './SettingsInterface'
import MachineInterface from '../../machines/components/MachineInterface'
import * as Yup from 'yup' // Import Yup for validation
import {useFormik} from 'formik' // Import useFormik hook from formik library
import MultiSelect from '../../../GenericComponents/MultiSelect'

interface ModalProps {
  show: boolean
  setShow: (state: boolean) => void
  fault: FaultInterface | null
  options: MachineInterface[]
  onSave: (updatedFault: FaultInterface) => void
  onRemove: ((removedFault: FaultInterface) => void) | null
}

const emptyFault: FaultInterface = {
  id: null,
  name: '',
  id_machines: undefined,
  updated_by: undefined,
  updated_at: undefined,
}

interface MachineOption {
  value: string
  label: string
}

const FaultModal = ({show, setShow, fault, options, onSave, onRemove}: ModalProps) => {
  const {t} = useTranslation(['settings'], {keyPrefix: 'fault.form'})
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [machines, setMachines] = useState<MachineOption[]>([])

  // Define the validation schema for the FaultInterface
  const faultSchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    id_machines: Yup.array(Yup.string())
      .min(1, t('machines.required'))
      .required(t('machines.required')),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: fault || emptyFault,
    validationSchema: faultSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
      resetForm()
    },
  })

  const handleRemove = () => {
    if (onRemove && fault) onRemove(fault)
    resetForm()
  }

  const resetForm = () => {
    setShow(false)
    formik.resetForm()
  }

  useEffect(() => {
    formik.setFieldValue('id_machines', selectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  useEffect(() => {
    if (show) {
      setMachines(options.map((o) => ({value: o.id ? o.id.toString() : '', label: o.name})))
      if (fault && fault.id_machines) setSelectedOptions(fault.id_machines)
      else setSelectedOptions([])
    }
  }, [show, fault, options])

  return (
    <Modal show={show} onHide={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>{t(`title.${fault ? 'edit' : 'add'}`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className='mb-10'>
            <label className='required form-label'>{t('name.label')}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              maxLength={45}
              placeholder={t('name.placeholder')}
              {...formik.getFieldProps('name')}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className='text-danger'>{formik.errors.name}</div>
            ) : null}
          </div>

          <div className='mb-10'>
            <label className='required form-label'>{t('machines.label')}</label>
            <MultiSelect
              options={machines}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              allOption={t('machines.all')}
              noneOption={t('machines.none')}
            >
              {t('machines.placeholder')}
            </MultiSelect>
            {formik.touched.id_machines && formik.errors.id_machines ? (
              <div className='text-danger'>{formik.errors.id_machines}</div>
            ) : null}
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        {onRemove && fault ? (
          <Button variant='danger' disabled={formik.isSubmitting} onClick={handleRemove}>
            {t('button.delete')}
          </Button>
        ) : (
          <Button variant='secondary' disabled={formik.isSubmitting} onClick={resetForm}>
            {t('button.cancel')}
          </Button>
        )}
        <Button
          type='submit'
          variant='primary'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FaultModal
