import Select from 'react-select'

interface SelectProps {
  value: string | number
  label: string
}

interface Props {
  defaultValue?: string
  showDefaultValue?: boolean
  options: SelectProps[]
  currentOption: SelectProps | null
  onSelect: (value: React.SetStateAction<SelectProps | null>) => void
}

const SelectButton = ({
  options,
  currentOption,
  defaultValue,
  showDefaultValue,
  onSelect,
}: Props) => {
  // se houver apenas uma opção, mostra-a como opção atual
  if (!showDefaultValue && options.length === 1) {
    if (!currentOption) onSelect(options[0])
    return <p className='text-dark'>{currentOption?.label ?? ''}</p>
  }

  const defaultOption = {value: null, label: defaultValue}

  return (
    <Select
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      options={showDefaultValue ? [defaultOption, ...options] : options}
      placeholder={currentOption ? currentOption.label : defaultValue}
      value={currentOption ? currentOption : showDefaultValue ? defaultOption : undefined}
      onChange={(newValue) => onSelect(newValue?.value ? newValue : null)}
    />
  )
}

export default SelectButton
