import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'

interface ButtonProps {
  selectMachine: () => void
  color?: string
  size?: string
}

const AddButton = ({selectMachine}: ButtonProps) => {
  const {t} = useTranslation(['machine'], {keyPrefix: 'tooltip'})
  const {isUserAllowed} = useAuth()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    selectMachine()
    event.currentTarget.blur()
  }

  // Verificar se utilizador tem permissão para realizar esta ação
  if (!isUserAllowed('machines', 'edit')) return null // ou fallback UI

  return (
    <button
      type='button'
      onClick={handleClick}
      className='btn btn-icon btn-active-color-primary'
      title={t('add_machine')}
    >
      <KTIcon iconName='plus-square' className='fs-4x' />
    </button>
  )
}

const EditButton = ({selectMachine, color, size}: ButtonProps) => {
  const {t} = useTranslation(['machine'], {keyPrefix: 'tooltip'})
  const [style, setStyle] = useState([''])
  const {isUserAllowed} = useAuth()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    selectMachine()
    event.currentTarget.blur()
  }

  useEffect(() => {
    if (color) setStyle((styles) => [...styles, `btn-icon-${color}`])
    if (size) setStyle((styles) => [...styles, `btn-${size}`])

    return () => setStyle([])
  }, [color, size])

  // Verificar se utilizador tem permissão para realizar esta ação
  if (!isUserAllowed('machines', 'edit')) return null // ou fallback UI

  return (
    <button
      type='button'
      onClick={handleClick}
      className={`btn btn-icon btn-active-color-primary ${style.join(' ')}`}
      title={t('edit_machine')}
    >
      <KTIcon iconName='notepad-edit' className='fs-2x' />
    </button>
  )
}

export {AddButton, EditButton}
