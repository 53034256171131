import {useTranslation} from 'react-i18next'
import {Task} from '../../../pages/maintenance/components/TaskInterface'
import {TemplateInterface} from '../../../pages/templates/components/TemplateInterface'
import {useTemplateContext} from '../../templates'
import {getNextActions} from '../../templates/components/Functions'

const StatusBadge: React.FC<{task: Task}> = ({task}) => {
  const {t} = useTranslation(['tasks'], {keyPrefix: 'state'})
  const {templates} = useTemplateContext()

  const currentTemplate = templates.find((t) => t.id === task.id_template)
  if (!currentTemplate) return null

  const nextActions: string[] = getNextActions(currentTemplate, task)
  if (!nextActions.length)
    return <span className='badge fs-6 fw-bold badge-light-success'>{t('completed')}</span>

  // Última ação executada
  const lastAction = task.activity?.slice(-1)[0]?.type

  // Cor de fundo do Badge
  const itemColor = lastAction === 'start' ? 'primary' : 'warning'
  // Texto do Badge
  const itemText =
    lastAction === 'start'
      ? 'progress'
      : nextActions[0] === 'validate'
        ? 'pending_validation'
        : lastAction === 'approve'
          ? 'approved'
          : 'pending'

  return <span className={`badge fs-6 fw-bold badge-light-${itemColor}`}>{t(itemText)}</span>
}

const StatusProgressBar: React.FC<{task: Task}> = ({task}) => {
  const {templates} = useTemplateContext()
  const currentTemplate = templates.find((t) => t.id === task.id_template)

  if (!currentTemplate) return null

  // Cor de fundo do Badge
  const type = task.activity?.slice().reverse()[0]?.type,
    itemColor = task.ended_at ? 'success' : type === 'start' ? 'primary' : 'warning'

  return (
    <div className='h-4px w-100 bg-light mb-5' data-bs-toggle='tooltip' title={task.name}>
      <div
        className={`bg-${itemColor} rounded h-4px`}
        role='progressbar'
        style={{width: `${getProgress(task, currentTemplate)}%`}}
      ></div>
    </div>
  )
}

const NextActions: React.FC<{task: Task}> = ({task}) => {
  const {t} = useTranslation(['tasks'])
  const {templates} = useTemplateContext()

  if (task.ended_at) return null

  const template = templates.find((t) => t.id === task.id_template)
  if (!template) return null

  const nextActions: string[] = getNextActions(template, task)
  if (!nextActions.length) return null

  return (
    <h6 className='fs-7 text-gray-400 mt-5 mb-0'>
      {t('modal.card.next_actions')}: {nextActions.map((type) => t(`actions.${type}`)).join(', ')}
    </h6>
  )
}

const getProgress = (task: Task, template: TemplateInterface) => {
  if (task.ended_at) return 100

  const allowedActions = Object.keys(template.actions).filter(
    (action) => template.actions[action].active
  )

  // Ação só foi criada
  if (!task.activity) return ((allowedActions.length - 1) / allowedActions.length) * 100

  const nextActions: string[] = getNextActions(template, task),
    actionsLeft = allowedActions.length - nextActions.length
  return (actionsLeft / allowedActions.length) * 100
}

export {getProgress, StatusBadge, StatusProgressBar, NextActions}
