import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'

const locales = ['pt', 'en']
const namespaces = [
  'auth',
  'navigation',
  'home',
  'machine',
  'calendar',
  'tasks',
  'teams',
  'users',
  'templates',
  'permissions',
  'account',
  'settings',
]
const resources = {}

locales.forEach((locale) => {
  resources[locale] = {}
  namespaces.forEach((namespace) => {
    resources[locale][namespace] = require(`./app/locales/${locale}/${namespace}.json`)
  })
})

// Get the saved language from localStorage or use 'pt' as the default
const savedLanguage = localStorage.getItem('selectedLanguage') || 'pt'

i18next.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  debug: false,
  fallbackLng: 'pt',
  saveMissing: true,
})

// Set the selected language in localStorage
i18next.on('languageChanged', (lng) => {
  localStorage.setItem('selectedLanguage', lng)
})

export default i18next
