import {TemplateInterface} from '../../../pages/templates/components/TemplateInterface'

const defaultTemplates: TemplateInterface[] = [
  {
    id: -1,
    name: 'corrective',
    actions: {
      create: {active: true},
      approve: {active: true},
      seen: {active: true},
      start: {active: true},
      end: {active: true},
      validate: {active: true},
    },
    schedule: false,
  },
  {
    id: 0,
    name: 'preventive',
    actions: {
      create: {active: true},
      approve: {active: true},
      seen: {active: true},
      start: {active: true},
      end: {active: true},
      validate: {active: true},
    },
    schedule: true,
  },
]

export default defaultTemplates
