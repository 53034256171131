import {useTranslation} from 'react-i18next'
import {KTIcon} from '../../../../_metronic/helpers'

interface ButtonProp {
  setUser: () => void
}

const AddButton = ({setUser}: ButtonProp) => {
  const {t} = useTranslation(['users'])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUser()
    event.currentTarget.blur()
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className='btn btn-sm btn-bg-light btn-active-primary'
      title={t('tooltip.add_user')}
    >
      <KTIcon iconName='user-edit' className='fs-2x' />
      {t('card.add_button')}
    </button>
  )
}

const EditButton = ({setUser}: ButtonProp) => {
  const {t} = useTranslation(['users'], {keyPrefix: 'tooltip'})

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUser()
    event.currentTarget.blur()
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className='btn btn-icon btn-active-color-primary'
      title={t('edit_user')}
    >
      <KTIcon iconName='user-edit' className='fs-2x' />
    </button>
  )
}

export {AddButton, EditButton}
