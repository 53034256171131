export interface PasswordInterface {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export const updatePassword: PasswordInterface = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export interface PinInterface {
  currentPassword: string
  newPin: string
}

export const updatePin: PinInterface = {
  currentPassword: '',
  newPin: '',
}
