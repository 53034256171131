import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {
  TemplateInterface,
  UserTemplateInterface,
} from '../../pages/templates/components/TemplateInterface'
import defaultTemplates from './components/defaultTemplates'
import {socket} from '../socket'
import {useAuth} from '../auth'
import {getUserActions} from './components/Functions'

interface TemplateContextType {
  templates: TemplateInterface[]
  addTemplate: (template: TemplateInterface) => Promise<void>
  updateTemplate: (template: TemplateInterface) => Promise<void>
  removeTemplate: (id: number) => Promise<void>
}

const TemplateContext = createContext<TemplateContextType | undefined>(undefined)

export const useTemplateContext = () => {
  const context = useContext(TemplateContext)
  if (!context) {
    throw new Error('useTemplateContext must be used within a TemplateProvider')
  }
  return context
}

export function TemplateContextProvider({children}) {
  const [templates, setTemplates] = useState<TemplateInterface[]>([])
  const {auth, setCurrentUser} = useAuth()

  useEffect(() => {
    // Handle socket events
    socket.connect()

    socket.on('connect', () => {
      console.debug('templateContext connected to server', socket.id)
    })

    socket.on('disconnect', () => {
      console.debug('templateContext disconnected from server')
    })

    socket.on('template-removed', (removedTemplate) => {
      setTemplates((prevTemplates) => prevTemplates.filter((m) => m.id !== removedTemplate.id))

      setCurrentUser((prevUser) => {
        if (!prevUser || !prevUser.templates) return prevUser
        // Atualiza na lista de templates do utilizador
        prevUser.templates = prevUser.templates.filter((m) => m.id !== removedTemplate.id)
        return prevUser
      })
    })

    socket.on('template-updated', (updatedTemplate) => {
      setTemplates((prevTemplates) =>
        prevTemplates.map((m) =>
          m.id === updatedTemplate.id ? Object.assign(m, updatedTemplate) : m
        )
      )

      setCurrentUser((prevUser) => {
        if (!prevUser || !prevUser.templates) return prevUser
        // Atualiza na lista de templates do utilizador
        prevUser.templates = prevUser.templates.map((m) => {
          if (m.id === updatedTemplate.id)
            return {
              id: updatedTemplate.id,
              name: updatedTemplate.name,
              schedule: updatedTemplate.schedule,
              actions: getUserActions(updatedTemplate.actions),
            } as UserTemplateInterface
          return m
        })

        return prevUser
      })
    })

    socket.on('template-inserted', (insertedTemplate) => {
      setTemplates((prevTemplates) => {
        if (insertedTemplate.name === 'corrective' || insertedTemplate.name === 'preventive')
          return prevTemplates.map((t) =>
            t.name === insertedTemplate.name ? Object.assign(t, insertedTemplate) : t
          )

        return [...prevTemplates, insertedTemplate]
      })

      setCurrentUser((prevUser) => {
        if (!prevUser || !insertedTemplate.id) return prevUser

        // Atualiza na lista de templates do utilizador
        const newTemplate: UserTemplateInterface = {
          id: insertedTemplate.id,
          name: insertedTemplate.name,
          schedule: insertedTemplate.schedule,
          actions: getUserActions(insertedTemplate.actions),
        } as UserTemplateInterface

        if (!prevUser.templates) prevUser.templates = [newTemplate]
        else prevUser.templates.push(newTemplate)

        return prevUser
      })
    })

    return () => {
      socket.off('template-removed')
      socket.off('template-updated')
      socket.off('template-inserted')
    }
  }, [])

  const fetchTemplates = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/template/list`)
      .then((response) => {
        const apiTemplates = response.data,
          corrective = Object.assign(
            defaultTemplates[0],
            apiTemplates.find((m: TemplateInterface) => m.name === 'corrective') || {}
          ),
          preventive = Object.assign(
            defaultTemplates[1],
            apiTemplates.find((m: TemplateInterface) => m.name === 'preventive') || {}
          )

        setTemplates([
          corrective,
          preventive,
          ...apiTemplates.filter(
            (m: TemplateInterface) => m.name !== 'corrective' && m.name !== 'preventive'
          ),
        ])
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeTemplate = async (id: number) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/template/${id}`)
      .then((response) => {
        console.log(response)

        setTemplates((prevTemplates) => prevTemplates.filter((m) => m.id !== id))

        setCurrentUser((prevUser) => {
          if (!prevUser || !prevUser.templates) return prevUser
          // Atualiza na lista de templates do utilizador
          prevUser.templates = prevUser.templates.filter((m) => m.id !== id)
          return prevUser
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateTemplate = async (template: TemplateInterface) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/template/${template.id}`, template)
      .then((response) => {
        console.log(response)

        setTemplates((prevTemplates) =>
          prevTemplates.map((t) => (t.id === template.id ? Object.assign(t, template) : t))
        )

        setCurrentUser((prevUser) => {
          if (!prevUser || !prevUser.templates) return prevUser
          // Atualiza na lista de templates do utilizador
          prevUser.templates = prevUser.templates.map((m) => {
            if (m.id === template.id)
              return {
                id: template.id,
                name: template.name,
                schedule: template.schedule,
                actions: getUserActions(template.actions),
              } as UserTemplateInterface

            return m
          })
          return prevUser
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addTemplate = async (template: TemplateInterface) => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/template`, template)
      .then((response) => {
        console.log(response)

        const {id_template, name} = response.data
        setTemplates((prevTemplates) => {
          if (name === 'corrective' || name === 'preventive')
            return prevTemplates.map((t) =>
              t.name === name ? Object.assign(t, template, {id: id_template}) : t
            )

          template.id = id_template
          return [...prevTemplates, template]
        })

        setCurrentUser((prevUser) => {
          if (!prevUser || !template.id) return prevUser

          // Atualiza na lista de templates do utilizador
          const newTemplate = {
            id: template.id,
            name: template.name,
            schedule: template.schedule,
            actions: getUserActions(template.actions),
          } as UserTemplateInterface
          if (!prevUser.templates) prevUser.templates = [newTemplate]
          else prevUser.templates.push(newTemplate)

          return prevUser
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  useEffect(() => {
    if (auth) fetchTemplates()
  }, [auth])

  return (
    <TemplateContext.Provider value={{templates, addTemplate, updateTemplate, removeTemplate}}>
      {children}
    </TemplateContext.Provider>
  )
}
