import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import {getBackground, formatLocaleDate} from '../../../helpers'
import {useMachineContext} from '../../../modules/machines'
import {EditButton} from './MachineButtons'
import MachineInterface from './MachineInterface'

interface Prop {
  selectMachine: (machine: MachineInterface | null) => void
}

const MachineCards = ({selectMachine}: Prop) => {
  const {t} = useTranslation(['machine'], {keyPrefix: 'list'})
  const {machines} = useMachineContext()

  const cardList = useMemo(
    () =>
      machines.map((machine: MachineInterface, index: number) => {
        const current = {
            date: machine.current_task?.activity.find((a) => a.type === 'start')?.created_at,
            name: machine.current_task?.name,
          },
          next = machine.next_task

        return (
          <div key={index} className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <div
              className={`card card-custom card-p-0 ${getBackground(machine.operational ? 0 : 1)}`}
            >
              <div className='card-body p-2'>
                <div className='d-flex flex-row flex-column-fluid'>
                  <div className='d-flex flex-row-auto w-125px flex-center'>
                    <div className='symbol symbol-125px'>
                      <img
                        src={toAbsoluteUrl(
                          machine?.pic ? machine.pic : '/media/avatars/blank-machine.png'
                        )}
                        alt={machine.name}
                      />
                    </div>
                  </div>

                  <div className='d-flex flex-column flex-row-fluid ps-2 pt-2'>
                    <div className='d-flex flex-column'>
                      <span className='h2 text-white'>{machine.name}</span>
                    </div>

                    {current.date && (
                      <div className='d-flex flex-column mt-auto text-white'>
                        <span className='fw-bold'>{t('current')}</span>
                        <span>{`${current.name}, ${formatLocaleDate(current.date)}`}</span>
                      </div>
                    )}

                    {next?.scheduled_for && (
                      <div className='d-flex flex-column mt-auto text-white'>
                        <span className='fw-bold'>{t('next')}</span>
                        <span>{`${next.name}, ${formatLocaleDate(next.scheduled_for)}`}</span>
                      </div>
                    )}
                  </div>

                  <div className='d-flex flex-column flex-row-auto'>
                    <div className='d-flex flex-column'>
                      <EditButton
                        selectMachine={() => selectMachine(machine)}
                        color='light'
                        size='sm'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }),
    [machines]
  )

  return <div className='row g-5 my-5'>{cardList}</div>
}
export default MachineCards
