import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {formatDate} from '../../../helpers'
import InputSearch from '../../../GenericComponents/InputSearch'
import {useMachineContext} from '../../../modules/machines'
import {FaultInterface} from './SettingsInterface'
import FaultModal from './FaultListModal'
import {AddButton, EditButton} from './ModalButtons'

interface Props {
  list: FaultInterface[] | null
  onSave: (updatedFault: FaultInterface) => void
  onRemove: (removedFault: FaultInterface) => void
}

const FaultAccess = ({list, onSave, onRemove}: Props) => {
  const {t} = useTranslation(['settings'], {keyPrefix: 'fault'})

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedFault, setSelectedFault] = useState<FaultInterface | null>(null)
  const [showModal, setShowModal] = useState(false)
  const {machines} = useMachineContext()

  const handleModal = (user: FaultInterface | null) => {
    setSelectedFault(user)
    setShowModal(true)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{t('title')}</h3>
          </div>
          <div className='card-toolbar'>
            {list?.length ? (
              <InputSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                className='w-250px'
              >
                {t('search')}
              </InputSearch>
            ) : null}
            <AddButton onClick={() => handleModal(null)} />
          </div>
        </div>
        {list?.length ? (
          <div className='card-body'>
            <table className='table table-row-bordered align-middle gy-2 gs-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>{t('table.name')}</th>
                  <th>{t('table.by')}</th>
                  <th>{t('table.at')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.map((access: FaultInterface, index: number) =>
                  access.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  searchTerm === '' ? (
                    <tr key={index}>
                      <td>{access.name}</td>
                      <td>{access.updated_by}</td>
                      <td>{access.updated_at ? formatDate(access.updated_at) : ''}</td>
                      <td>
                        <EditButton onClick={() => handleModal(access)} />
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <FaultModal
        show={showModal}
        setShow={setShowModal}
        fault={selectedFault}
        options={machines}
        onSave={onSave}
        onRemove={onRemove}
      />
    </>
  )
}

export default FaultAccess
