import {useTranslation} from 'react-i18next'
import PermissionBadge from '../../../modules/permissions/components/PermissionBadge'

const ProfilePermissions = () => {
  const {t} = useTranslation(['account'], {keyPrefix: 'permissions'})

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_permissions'
        aria-expanded='true'
        aria-controls='kt_account_profile_permissions'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{t('title')}</h3>
        </div>
      </div>

      <div id='kt_account_profile_permissions' className='collapse show'>
        <div className='card-body border-top p-9'>
          <PermissionBadge />
        </div>
      </div>
    </div>
  )
}

export default ProfilePermissions
