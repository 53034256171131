import {useTranslation} from 'react-i18next'

interface CheckboxProps {
  label: string
  onChange: () => void
  checked?: boolean
}

const PermissionCheckbox = ({label, onChange, checked}: CheckboxProps) => {
  const {t} = useTranslation(['permissions'])

  return (
    <div key={label} className='col'>
      <label className='form-check form-check-inline form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          name={label}
          onChange={onChange}
          checked={checked}
        />
        <span className='text-nowrap ps-2 fs-6'>{t(label)}</span>
      </label>
    </div>
  )
}

export default PermissionCheckbox
