import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {formatDate} from '../../helpers'
import {useAuth} from '../../modules/auth'
import {useUserContext} from '../../modules/users'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import InputSearch from '../../GenericComponents/InputSearch'
import {TeamInterface} from './components/TeamInterface'
import {AddButton, EditButton} from './components/TeamButtons'
import TeamModal from './components/TeamModal'

const Teams: FC = () => {
  const {t} = useTranslation(['teams'])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedTeam, setSelectedTeam] = useState<TeamInterface | null>(null)
  const [showModal, setShowModal] = useState(false)
  const {users, teams, addTeam, updateTeam, removeTeam} = useUserContext()
  const {isUserAllowed} = useAuth()

  const handleModal = (team: TeamInterface | null) => {
    setSelectedTeam(team)
    setShowModal(true)
  }

  const handleSave = (updatedTeam: TeamInterface) => {
    if (!isUserAllowed('teams', 'edit')) {
      toast.warn(t('message.auth'))
      return
    }

    // Editar Equipa
    if (updatedTeam.id) {
      toast.promise(updateTeam(updatedTeam), {
        pending: t('message.pending'),
        success: t('message.edit.success'),
        error: t('message.edit.error'),
      })
    }
    // Nova Equipa
    else {
      toast.promise(addTeam(updatedTeam), {
        pending: t('message.pending'),
        success: t('message.insert.success'),
        error: t('message.insert.error'),
      })
    }
  }

  const handleRemove = (removedTeam: TeamInterface) => {
    if (!removedTeam.id || !isUserAllowed('teams', 'delete')) {
      toast.warn(t('message.auth'))
      return
    }

    toast.promise(removeTeam(removedTeam.id), {
      pending: t('message.pending'),
      success: t('message.delete.success'),
      error: t('message.delete.error'),
    })
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>{t('title')}</h3>

          <div className='card-toolbar'>
            {teams.length ? (
              <InputSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                className='w-250px'
              >
                {t('search')}
              </InputSearch>
            ) : null}

            {isUserAllowed('teams', 'edit') && <AddButton onClick={() => handleModal(null)} />}
          </div>
        </div>
        {teams.length ? (
          <div className='card-body'>
            <table className='table table-row-bordered align-middle gy-2 gs-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>{t('table.name')}</th>
                  <th>{t('table.members')}</th>
                  <th>{t('table.by')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team: TeamInterface, index: number) => {
                  const teamUsers = users.filter((u) => u.id && team.id_users?.includes(u.id))
                  return team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    searchTerm === '' ? (
                    <tr key={index}>
                      <td>{team.name}</td>
                      <td>
                        <div className='symbol-group symbol-hover mb-1'>
                          {teamUsers.slice(0, 5).map((user) => (
                            <div className='symbol symbol-35px symbol-circle' key={user.id}>
                              <img
                                src={toAbsoluteUrl(user.pic ?? '/media/avatars/blank.png')}
                                alt={user.name}
                              />
                            </div>
                          ))}
                          {teamUsers.length > 5 ? (
                            <div className='symbol symbol-35px symbol-circle'>
                              <div className='symbol-label bg-dark'>
                                <span className='fs-8 text-inverse-dark'>
                                  +{teamUsers.length - 5}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        {team.updated_by}
                        {team.updated_at ? (
                          <span className='text-muted ms-2'>{formatDate(team.updated_at)}</span>
                        ) : null}
                      </td>
                      <td>
                        {isUserAllowed('teams', 'edit') && (
                          <EditButton onClick={() => handleModal(team)} />
                        )}
                      </td>
                    </tr>
                  ) : null
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <TeamModal
        show={showModal}
        setShow={setShowModal}
        team={selectedTeam}
        onSave={handleSave}
        onRemove={handleRemove}
      />
    </>
  )
}
export default Teams
