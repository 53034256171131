import {useTranslation} from 'react-i18next'

interface ButtonProps {
  isSelected: boolean
  onClick: () => void
}

const PermissionButton = ({isSelected, onClick}: ButtonProps) => {
  const {t} = useTranslation(['permissions'])

  return (
    <button type='button' className='btn btn-link btn-sm text-primary p-0' onClick={onClick}>
      {t(isSelected ? 'select.none' : 'select.all')}
    </button>
  )
}

export default PermissionButton
